import { Modal, Button, IconButton, Input } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import CloseIcon from "@mui/icons-material/Close";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { showError, showToast } from "../constants/toasts";
import {
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  successToast,
} from "../helpers";
import { useAppDispatch } from "../hooks/store";
import { usePutUpdateProfileMutation } from "../services/profile";
import { UploadMedia } from "../utils/uploadMedia";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { setCredentials } from "../reducers/authSlice";
import { CommonBody } from "../types/General";
import { generateEncryptedKeyBody } from "../utils/crypto";
import {
  useAddSubAdminMutation,
  useEditSubAdminByIdMutation,
} from "../services/subAdmin";
import { boolean } from "yup";

interface AddUserModalProps {
  open: boolean;
  onClose: () => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
  roles?: any;
  getSubAdminsList?: any;
  selectedData?: any;
}

export default function AddUser({
  open,
  onClose,
  setOpen,
  roles,
  getSubAdminsList,
  selectedData,
}: AddUserModalProps) {
  const [selectedRole, setSelectedRole] = useState("");

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const user = useAuth();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [updateprofile]: any = usePutUpdateProfileMutation();
  const [phone, setPhone] = useState("+91");
  const [phoneCode, setPhoneCode] = useState("+91");
  const [profile_picture, setProfilePicture] = useState<any>(null);
  const [imageId, setImageId] = useState(null);
  const [phoneDisplay, setPhoneDisplay] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [addSubAdmin] = useAddSubAdminMutation();
  const [editSubAdminById] = useEditSubAdminByIdMutation();
  const handleAddUpdateSubAdmin = async () => {
    try {
      const body = {
        image: profile_picture,
        subadminRoleId: selectedRole,
        fullName: fullName,
        email: email,
        phone: phone,
        countryCode: phoneCode,
      };

      const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = selectedData
        ? await editSubAdminById({
            id: selectedData?.id,
            body: encryptedBody,
          }).unwrap()
        : await addSubAdmin(encryptedBody).unwrap();
      if (response.statusCode == 200) {
        showToast(
          selectedData
            ? "Subadmin updated succesfully"
            : "Subadmin added succesfully"
        );
        if (selectedData?.id == user?._id) {
          dispatch(
            setCredentials({
              token: getFromStorage(STORAGE_KEYS.token),
              user: { ...user, image: profile_picture },
            })
          );
        }
        setOpen(false);
        setError(false);
        getSubAdminsList();
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    setError(true);
    if (fullName.trim() == "") return;
    if (email.trim() == "") return;
    if (phone.trim() == "") return;
    handleAddUpdateSubAdmin();
  };
  const UpdateData = async () => {
    try {
      let body: {
        // email: string;
        fullName: string;
        countryCode: string;
        phone: string;
        image?: string;
      } = {
        // email: values.email,
        fullName: fullName,
        countryCode: phoneCode,
        phone: phone,
        image: profile_picture,
      };

      //        console.log(body, "body")
      // if (values.profile_picture) {
      //   body.image = values.profile_picture;
      // }
      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await updateprofile(encryptedBody).unwrap();
      if (response?.statusCode === 200) {
        dispatch(
          setCredentials({
            user: { ...user, fullName: fullName, image: profile_picture },
            token: getFromStorage(STORAGE_KEYS.token),
          })
        );
        successToast("Profile Updated Successfully.");
        const token = getFromStorage(STORAGE_KEYS.token);
        //    navigate("/dashboard");
        setOpen(false);
      }
    } catch (error: any) {
      errorToast("error");
    }
  };
  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    const file = files?.length ? files[0] : null;

    try {
      if (!file) {
        setProfilePicture("");
        setImageId(null);
        return;
      }
      const allowedExtensions = ["png", "jpg", "jpeg"];
      const fileExtension = file.name.split(".").pop()?.toLowerCase();
      console.log("sssssssssssssssssssss", fileExtension);
      if (!allowedExtensions.includes(fileExtension || "")) {
        setIsLoading(false);
        showError("Invalid file format: only png, jpg images are allowed");
        return;
      }
      setIsLoading(true);
      const res = await UploadMedia(file, fileExtension);
      //      console.log(res,'lklk');

      if (res?.statusCode === 200) {
        setProfilePicture(res?.data);
      } else {
        errorToast(res?.message);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      errorToast("Error uploading image. Please try again.");
    }
    setIsLoading(false);
  };
  const handleChangePhone = (phone: any, country: any) => {
    setPhone(phone.replace(country.dialCode, ""));
    setPhoneCode(country.dialCode);
    setPhoneDisplay(phone);
  };
  const [isDisplayRole, setIsDisplayRole] = useState<boolean>(true);
  useEffect(() => {
    if (selectedData) {
      setPhone(selectedData?.phone);
      setPhoneCode(selectedData?.countryCode);
      setPhoneDisplay(selectedData?.countryCode + selectedData?.phone);
      setEmail(selectedData?.email);
      setFullName(selectedData?.name);
      setProfilePicture(selectedData?.image);
      setSelectedRole(selectedData?.roleId);
      setIsDisplayRole(selectedData?.roleType == 3);
    }
  }, [open]);

  // console.log("user ROLE IS 2 :"+(user?.role==2) ,"IS EDIT :" +isEdit)
  return (
    <Modal
      className="modal addUser_modal"
      id="addUserModal"
      aria-labelledby="addUser-modal-title"
      aria-describedby="addUser-modal-description"
      open={open}
      onClose={() => {
        setError(false);
        setOpen(false);
        setFullName("");
        setEmail("");
        setPhone("");
        setEmail("");
        setProfilePicture("");
        setPhoneCode("");
        setPhone("");
        setPhoneDisplay("");
        setIsDisplayRole(true);
        onClose();
      }}
    >
      <div className="modal-dialog">
        <div className="modal-body">
          <div className="btn-close" onClick={() => setOpen(false)}>
            <CloseIcon />
          </div>
          <div className="modal_title">
            <h2>{"Add user"}</h2>
            <p>{"Send access to a user by filling the below details"}</p>
          </div>
          <form className="form" action="" onSubmit={handleSubmit}>
            <div className="gap_p">
              <div className="control_group w_100">
                <div className="upload_box">
                  <label>
                    {profile_picture && (
                      <IconButton
                        className="crossIcon"
                        onClick={() => setProfilePicture("")}
                      >
                        <CloseIcon />
                      </IconButton>
                    )}
                    <figure>
                      <img
                        src={
                          profile_picture
                            ? profile_picture
                            : "/static/images/user-placeholder.jpg"
                        }
                        alt="Upload"
                      />
                    </figure>
                    <IconButton className="uploadIcon">
                      <label htmlFor="icon-button-file">
                        <Button component="span" className="upload_image_btn">
                          <img
                            src="/static/images/edit2_icon.svg"
                            alt="Upload"
                          />
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                            onChange={(e: any) => {
                              handleImageUpload(e);
                              e.target.value = null;
                            }}
                          />
                        </Button>
                      </label>
                    </IconButton>
                  </label>
                </div>
              </div>
              <div className="control_group w_50">
                <TextField
                  variant="outlined"
                  placeholder="Full name"
                  fullWidth
                  hiddenLabel
                  inputProps={{
                    maxLength: 25,
                  }}
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                />
                {error && fullName.trim() == "" ? (
                  <h6 className="err_msg">Name is required</h6>
                ) : (
                  ""
                )}
              </div>
              <div className="control_group w_50">
                <TextField
                  variant="outlined"
                  placeholder="Email address"
                  fullWidth
                  hiddenLabel
                  value={email}
                  disabled={selectedData}
                  inputProps={{
                    maxLength: 40,
                  }}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {error && email.trim() == "" ? (
                  <h6 className="err_msg">Email is required</h6>
                ) : (
                  ""
                )}
              </div>
              <div className="control_group w_100">
                <PhoneInput
                  country={"us"}
                  placeholder="0 (000) 000-000"
                  enableSearch={true}
                  inputClass="phoneInput"
                  inputStyle={{ width: "100%" }}
                  value={phoneDisplay}
                  buttonClass="phoneBtn"
                  // onChange={(value) => formik.setFieldValue("phone", value)}
                  onChange={(phone, country) =>
                    handleChangePhone(phone, country)
                  }
                />
                {error && phone.trim() == "" ? (
                  <h6 className="err_msg">Phone is required</h6>
                ) : (
                  ""
                )}
              </div>
            </div>
            {isDisplayRole && (
              <>
                <h3>Role</h3>
                <ul className="roles_list">
                  <RadioGroup
                    aria-labelledby="role-label"
                    defaultValue="administrator"
                    name="role-buttons-group"
                    value={selectedRole}
                    onChange={(e: any) => setSelectedRole(e.target.value)}
                  >
                    {roles?.map((data: any) => {
                      return (
                        <FormControlLabel
                          key={data?._id}
                          value={data._id}
                          control={<Radio />}
                          label={data?.role}
                        />
                      );
                    })}
                  </RadioGroup>
                </ul>
              </>
            )}
            <div className="footer_btn_flex">
              <Button
                className="btn btn_highlight"
                onClick={() => {
                  onClose();
                  setError(false);
                  setOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button className="btn btn_secondary" type="submit">
                Submit
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}
