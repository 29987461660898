/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Button, IconButton } from "@mui/material";
import TableComponent from "../../../components/TableComponent";
import { GridColDef } from "@mui/x-data-grid";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import GiveFeedback1 from "../../../Modals/giveFeedback1";
import GiveFeedback2 from "../../../Modals/giveFeedback2";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { ADMIN_ROLE_ID } from "../../../constants/role";
import { Feedback, ShipmentFeedback } from "../../../types/shipmentFeedback";
import { Cancel } from "@mui/icons-material";
import { showError } from "../../../constants/toasts";

const SubmittedList = ({
  columnVisibilityModel,
  feedback,
  setSelectedRow,
  gridApi,
  getDataList,
}: {
  columnVisibilityModel: any;
  feedback: any;
  setSelectedRow: any;
  gridApi: any;
  getDataList: any;
}) => {
  const navigate = useNavigate();

  const value1 = 5;

  const [openModal, setOpenModal] = useState(false);
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const [openModal2, setOpenModal2] = useState(false);
  const handleCloseModal2 = () => {
    setOpenModal2(false);
  };
  const [ratingData, setRatingData] = useState<Feedback | any>({});
  const rows: any = [
    // {
    //     id: 1,
    //     shipments: "xxx 1263-64512",
    //     destinationCity: "Istanbul",
    //     currentCity: "Canberra",
    // },
    // {
    //     id: 2,
    //     shipments: "xxx 1263-64512",
    //     destinationCity: "Istanbul",
    //     currentCity: "Canberra",
    // },
    // {
    //     id: 3,
    //     shipments: "xxx 1263-64512",
    //     destinationCity: "Istanbul",
    //     currentCity: "Canberra",
    // },
    // {
    //     id: 4,
    //     shipments: "xxx 1263-64512",
    //     destinationCity: "Istanbul",
    //     currentCity: "Canberra",
    // },
    // {
    //     id: 5,
    //     shipments: "xxx 1263-64512",
    //     destinationCity: "Istanbul",
    //     currentCity: "Canberra",
    // },
  ];
  feedback.forEach((element: ShipmentFeedback) => {
    rows.push({
      id: element?._id,
      freightCustomer: element?.customer?.fullName
        ? element?.customer?.fullName
        : "-",
      freightCustomerDetail: element?.customer,
      traveler: element?.traveller?.fullName
        ? element?.traveller?.fullName
        : "-",
      travelerDetail: element?.traveller,
      beneficiary: element?.beneficiaryDetail?.name
        ? element?.beneficiaryDetail?.name
        : "-",
      beneficiaryDetail: element?.beneficiaryDetail,
      shipment: element?.orderId,
      currentCity: element?.beneficiaryDetail?.customerCurrentCity,
      destinationCity: element?.beneficiaryDetail?.city,
      feedback: element.feedback,
      status: element?.feedback?.length < 3 ? "Partly" : "Fully",
      userDetailsBeneficiary: element?.userDetailsBeneficiary,
    });
  });
  const user = useAuth();
  const columns: GridColDef<(typeof rows)[number]>[] = [
    {
      flex: 1,
      minWidth: 200,
      field: "freightCustomer",
      headerName: "Freight Customers",
      editable: false,
      renderCell: (data: any) => {
        const feedbackData: Feedback[] = data.row.feedback;
        let rating = -1;
        const feedbackFilteredData = feedbackData?.find(
          (data) => data?.reportedBy == 2
        );
        if (feedbackFilteredData) {
          rating =
            feedbackFilteredData?.overallExperienceWithTraveller as number;
        }
        return (
          <div className="user_block">
            <figure
              onClick={() =>
                user?.role == ADMIN_ROLE_ID ||
                user?.subadminRoleId?.permissions?.[6]?.isView
                  ? navigate(
                      "/profile-detail/" + data?.row?.freightCustomerDetail?._id
                    )
                  : null
              }
            >
              <img
                src={
                  data?.row?.freightCustomerDetail?.personVerification
                    ?.profileImage || "/static/images/user-placeholder.jpg"
                }
                alt=""
              />
            </figure>
            <p className="flexD cursor_p">
              <b
                onClick={
                  () => {
                    if (rating == -1) {
                      showError("No Feedback from freight customer");
                      return;
                    }
                    setRatingData({
                      commitmentToDeliver:
                        feedbackFilteredData?.commitmentToDeliver, //by customer or beneficiary
                      receivedOnTime: feedbackFilteredData?.receivedOnTime, //by customer or beneficiary
                      qualityOfShipmentContent:
                        feedbackFilteredData?.qualityOfShipmentContent, //by customer or beneficiary
                      overallExperienceWithTraveller:
                        feedbackFilteredData?.overallExperienceWithTraveller, //by customer or beneficiary
                      feedback: feedbackFilteredData?.feedback,
                    });
                    setTimeout(() => {
                      setOpenModal2(true);
                    }, 100);
                  }
                  // ((user?.role == ADMIN_ROLE_ID || user?.subadminRoleId?.permissions?.[6]?.isView) ? navigate("/profile-detail/" + data?.row?.userId) : null)
                }
              >
                {`${data?.row?.freightCustomerDetail?.firstName} ${data?.row?.freightCustomerDetail?.lastName}` ||
                  "Michael Johnson"}
              </b>
              <a
                onClick={() => {
                  if (rating == -1) {
                    showError("No Feedback from freight customer");
                    return;
                  }
                  setRatingData({
                    commitmentToDeliver:
                      feedbackFilteredData?.commitmentToDeliver, //by customer or beneficiary
                    receivedOnTime: feedbackFilteredData?.receivedOnTime, //by customer or beneficiary
                    qualityOfShipmentContent:
                      feedbackFilteredData?.qualityOfShipmentContent, //by customer or beneficiary
                    overallExperienceWithTraveller:
                      feedbackFilteredData?.overallExperienceWithTraveller, //by customer or beneficiary
                    feedback: feedbackFilteredData?.feedback,
                  });
                  setTimeout(() => {
                    setOpenModal2(true);
                  }, 100);
                }}
              >
                <Rating
                  name="read-only"
                  value={rating || 0}
                  readOnly
                  precision={0.5}
                  emptyIcon={<StarIcon />}
                />
              </a>
            </p>
          </div>
        );
      },
    },
    {
      flex: 1,
      minWidth: 180,
      field: "traveler",
      headerName: "Travelers",
      editable: false,
      renderCell: (data: any) => {
        let rating = -1;
        const feedbackData: Feedback[] = data?.row?.feedback;
        const feedbackFilteredData = feedbackData?.find(
          (data) => data?.reportedBy == 1
        );
        if (feedbackFilteredData) {
          rating = feedbackFilteredData?.experienceWithGinaex as number;
        }
        return (
          <div className="user_block">
            <figure
              onClick={() =>
                user?.role == ADMIN_ROLE_ID ||
                user?.subadminRoleId?.permissions?.[6]?.isView
                  ? navigate(
                      "/profile-detail/" + data?.row?.travelerDetail?._id
                    )
                  : null
              }
            >
              <img
                src={
                  data?.row?.travelerDetail?.personVerification?.profileImage ||
                  "/static/images/user-placeholder.jpg"
                }
                alt=""
              />
            </figure>
            <p className="flexD cursor_p">
              <b
                onClick={
                  () => {
                    if (rating == -1) {
                      showError("No Feedback from traveller");
                      return;
                    }
                    setRatingData({
                      purposeOfTravel:
                        feedbackFilteredData?.purposeOfTravel || "",
                      experienceWithGinaex:
                        feedbackFilteredData?.experienceWithGinaex || 0,
                      lastTimeVisited:
                        feedbackFilteredData?.lastTimeVisited || "",
                      recommendToOthers:
                        feedbackFilteredData?.recommendToOthers || 0,
                      feedback: feedbackFilteredData?.feedback,
                    });
                    setTimeout(() => {
                      setOpenModal(true);
                    }, 100);
                  }
                  // ((user?.role == ADMIN_ROLE_ID || user?.subadminRoleId?.permissions?.[6]?.isView) ? navigate("/profile-detail/" + data?.row?.userId) : null)
                }
              >
                {`${data?.row?.travelerDetail?.firstName} ${data?.row?.travelerDetail?.lastName}` ||
                  "Emma smith"}
              </b>
              <a
                onClick={() => {
                  if (rating == -1) {
                    showError("No Feedback from traveller");
                    return;
                  }
                  setRatingData({
                    purposeOfTravel:
                      feedbackFilteredData?.purposeOfTravel || "",
                    experienceWithGinaex:
                      feedbackFilteredData?.experienceWithGinaex || 0,
                    lastTimeVisited:
                      feedbackFilteredData?.lastTimeVisited || "",
                    recommendToOthers:
                      feedbackFilteredData?.recommendToOthers || 0,
                    feedback: feedbackFilteredData?.feedback,
                  });
                  setTimeout(() => {
                    setOpenModal(true);
                  }, 100);
                }}
              >
                <Rating
                  name="read-only"
                  value={rating || 0}
                  readOnly
                  precision={0.5}
                  emptyIcon={<StarIcon />}
                />
              </a>
            </p>
          </div>
        );
      },
    },
    {
      flex: 1,
      minWidth: 180,
      field: "beneficiary",
      headerName: "Beneficiary",
      editable: false,
      renderCell: (data: any) => {
        const feedbackData: Feedback[] = data.row.feedback;
        let rating = -1;
        const feedbackFilteredData = feedbackData?.find(
          (data) => data?.reportedBy == 2
        );
        if (feedbackFilteredData) {
          rating =
            feedbackFilteredData?.overallExperienceWithTraveller as number;
        }
        return (
          <div className="user_block" style={{ position: "relative" }}>
            <figure
              onClick={() =>
                user?.role == ADMIN_ROLE_ID ||
                user?.subadminRoleId?.permissions?.[6]?.isView
                  ? data?.row?.beneficiaryDetail?.userId
                    ? navigate(
                        "/profile-detail/" +
                          data?.row?.beneficiaryDetail?.userId
                      )
                    : showError("Beneficiary not registered yet")
                  : null
              }
            >
              {!data?.row?.beneficiaryDetail?.userId && (
                <div style={{ position: "absolute", bottom: -15, left: 20 }}>
                  <Cancel fontSize="small" color="error" />
                </div>
              )}

              <img
                src={
                  data?.row?.beneficiaryDetail?.image ||
                  "/static/images/user-placeholder.jpg"
                }
                alt=""
              />
            </figure>
            <p className="flexD cursor_p">
              <b
                onClick={
                  () => {
                    if (rating == -1) {
                      showError("No Feedback from beneficiary");
                      return;
                    }
                    setRatingData({
                      commitmentToDeliver:
                        feedbackFilteredData?.commitmentToDeliver, //by customer or beneficiary
                      receivedOnTime: feedbackFilteredData?.receivedOnTime, //by customer or beneficiary
                      qualityOfShipmentContent:
                        feedbackFilteredData?.qualityOfShipmentContent, //by customer or beneficiary
                      overallExperienceWithTraveller:
                        feedbackFilteredData?.overallExperienceWithTraveller, //by customer or beneficiary
                      feedback: feedbackFilteredData?.feedback,
                    });
                    setTimeout(() => {
                      setOpenModal2(true);
                    }, 100);
                  }
                  // ((user?.role == ADMIN_ROLE_ID || user?.subadminRoleId?.permissions?.[6]?.isView) ? navigate("/profile-detail/" + data?.row?.userId) : null)
                }
              >
                {(data?.row?.userDetailsBeneficiary?.firstName
                  ? `${data?.row?.userDetailsBeneficiary?.firstName} ${data?.row?.userDetailsBeneficiary?.lastName}`
                  : data?.row?.beneficiaryDetail?.name) || "Usama Ali"}
              </b>
              <a
                onClick={() => {
                  if (rating == -1) {
                    showError("No Feedback from beneficiary");
                    return;
                  }
                  setRatingData({
                    commitmentToDeliver:
                      feedbackFilteredData?.commitmentToDeliver, //by customer or beneficiary
                    receivedOnTime: feedbackFilteredData?.receivedOnTime, //by customer or beneficiary
                    qualityOfShipmentContent:
                      feedbackFilteredData?.qualityOfShipmentContent, //by customer or beneficiary
                    overallExperienceWithTraveller:
                      feedbackFilteredData?.overallExperienceWithTraveller, //by customer or beneficiary
                    feedback: feedbackFilteredData?.feedback,
                  });
                  setTimeout(() => {
                    setOpenModal2(true);
                  }, 100);
                }}
              >
                <Rating
                  name="read-only"
                  value={rating || 0}
                  readOnly
                  precision={0.5}
                  emptyIcon={<StarIcon />}
                />
              </a>
            </p>
          </div>
        );
      },
    },
    {
      flex: 1,
      minWidth: 120,
      field: "shipment",
      headerName: "Shipment No",
      editable: false,
      sortable: false,
    },
    {
      flex: 1,
      minWidth: 150,
      field: "destinationCity",
      headerName: "Destination City",
      editable: false,
    },
    {
      flex: 1,
      minWidth: 120,
      field: "currentCity",
      headerName: "Current city",
      editable: false,
    },
    {
      flex: 1,
      minWidth: 130,
      field: "status",
      headerName: "Status",
      editable: false,
      sortable: false,
      renderCell: ({ row }: any) => {
        return (
          <Button
            className={
              row?.status == "Fully"
                ? "btn_text c_success"
                : "btn_text c_danger"
            }
          >
            {row?.status || "Fully"}
          </Button>
        );
      },
    },
  ];

  let hidden = ["fullName"];

  return (
    <>
      <div className="sorting_table">
        <TableComponent
          columns={columns}
          rows={rows}
          gridApi={gridApi}
          setSelectedRow={setSelectedRow}
          columnVisibilityModel={columnVisibilityModel}
        />
      </div>

      <GiveFeedback1
        open={openModal}
        onClose={handleCloseModal}
        setOpen={setOpenModal}
        data={ratingData}
      />
      <GiveFeedback2
        open={openModal2}
        onClose={handleCloseModal2}
        setOpen={setOpenModal2}
        data={ratingData}
      />
    </>
  );
};

export default SubmittedList;
