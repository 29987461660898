import { Modal, Button } from "@mui/material";
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import StarIcon from "@mui/icons-material/Star";
import Rating from "@mui/material/Rating";
import React from "react";
import { Review } from "../types/review";
import { useEditReviewByIdMutation } from "../services/review";
import { showToast, showError } from "../constants/toasts";
import { CommonBody } from "../types/General";
import { generateEncryptedKeyBody } from "../utils/crypto";
import { isValidEmail } from "../utils/validations";

interface EditReviewModalProps {
  open: boolean;
  onClose: () => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
  selectedData: Review;
  getData: any;
}

export default function EditReview({
  open,
  onClose,
  setOpen,
  selectedData,
  getData,
}: EditReviewModalProps) {
  const [update] = useEditReviewByIdMutation();
  const updateData = async () => {
    const body = {
      ...userData,
      rating: value,
    };
    try {
      const encryptedData = generateEncryptedKeyBody(body) as CommonBody;
      const response = await update({
        id: `${selectedData?._id}`,
        body: encryptedData,
      }).unwrap();
      if (response?.statusCode == 200) {
        showToast("Review Updated Successfully");
        getData();
        setOpen(false);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
      console.log(error);
    }
  };
  const [value, setValue] = React.useState<number | null>(
    selectedData?.rating as number
  );
  const [userData, setUserData] = useState<{
    name: string;
    email: string;
    description: string;
  }>({
    name: "",
    email: "",
    description: "",
  });
  useLayoutEffect(() => {
    if (selectedData) {
      setUserData({
        name: selectedData?.name as string,
        email: selectedData?.email as string,
        description: selectedData?.description as string,
      });
      setValue(selectedData?.rating as number);
      console.log(selectedData);
    }
  }, [open]);
  return (
    <Modal
      className="modal editReview_modal"
      id="editReviewModal"
      aria-labelledby="editReview-modal-title"
      aria-describedby="editReview-modal-description"
      open={open}
      onClose={onClose}
    >
      <div className="modal-dialog">
        <div className="modal-body">
          <div className="btn-close" onClick={() => setOpen(false)}>
            <CloseIcon />
          </div>
          <div className="modal_title">
            <h2>Edit Your Rating</h2>
            <p>Select your duration date</p>
          </div>
          <form className="form" action="">
            <div className="control_group">
              <Rating
                name="hover-feedback"
                value={value}
                precision={0.5}
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
                emptyIcon={
                  <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                }
              />
            </div>
            <div className="control_group">
              <TextField
                variant="outlined"
                placeholder="Name"
                fullWidth
                hiddenLabel
                value={userData?.name}
                onChange={(e) =>
                  setUserData((prev) => ({ ...prev, name: e.target.value }))
                }
              />
            </div>
            <div className="control_group">
              <TextField
                variant="outlined"
                placeholder="Email"
                fullWidth
                hiddenLabel
                value={userData?.email}
                onChange={(e) =>
                  setUserData((prev) => ({ ...prev, email: e.target.value }))
                }
              />
            </div>
            <div className="control_group">
              <TextField
                variant="outlined"
                placeholder="Description"
                fullWidth
                hiddenLabel
                multiline
                maxRows={5}
                minRows={5}
                value={userData?.description}
                onChange={(e) =>
                  setUserData((prev) => ({
                    ...prev,
                    description: e.target.value,
                  }))
                }
              />
            </div>
            <div className="footer_btn_flex">
              <Button
                className="btn btn_highlight"
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
              <Button
                className="btn btn_secondary"
                onClick={() => {
                  if (userData?.email.trim() == "") {
                    showError("Email is required");
                    return;
                  }
                  if (!isValidEmail(userData?.email)) {
                    showError("Invalid email address");
                    return;
                  }
                  if (userData?.name.trim() == "") {
                    showError("Name is required");
                    return;
                  }
                  if (userData?.description.trim() == "") {
                    showError("Description is required");
                    return;
                  }
                  updateData();
                }}
              >
                Save Changes
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}
